import React from "react"
import { Link } from "gatsby"

import DesignImg from "../../../assets/images/services_black_icons/design.svg"
import StrategyImg from "../../../assets/images/services_black_icons/strategy.svg"
import WebsiteImg from "../../../assets/images/services_black_icons/website.svg"
import MobileImg from "../../../assets/images/services_black_icons/mobile.svg"
import SeoImg from "../../../assets/images/services_black_icons/seo.svg"
import SemImg from "../../../assets/images/services_black_icons/sem.svg"
import OutsourcingImg from "../../../assets/images/services_black_icons/outsourcing.svg"
import SoftwareImg from "../../../assets/images/services_black_icons/software.svg"
    
export default () =>
    <div id="links-to-services">
        <Link id="design" to="/services/design">
            <img src={DesignImg} alt="Drupal and WordPress Development Company, ICON Worldwide, Design" title="Drupal and WordPress Development Company, ICON Worldwide, Design"/>
            <p>Design</p>
        </Link>
        <Link id="strategy" to="/services/marketing-strategy
">
            <img src={StrategyImg} alt="Drupal and WordPress Development Company, ICON Worldwide, strategy" title="Drupal and WordPress Development Company, ICON Worldwide, strategy"/>
            <p>Strategy</p>
        </Link>
        <Link id="website" to="/services/website-development">
            <img src={WebsiteImg} alt="Drupal and WordPress Development Company, ICON Worldwide, website" title="Drupal and WordPress Development Company, ICON Worldwide, website"/>
            <p>Website</p>
        </Link>
        <Link id="mobile" to="/services/mobile-development">
            <img src={MobileImg} alt="Drupal and WordPress Development Company, ICON Worldwide, mobile" title="Drupal and WordPress Development Company, ICON Worldwide, mobile"/>
            <p>Mobile</p>
        </Link>
        <Link id="software" to="/services/software-development">
            <img src={SoftwareImg} alt="Drupal and WordPress Development Company, ICON Worldwide, development" title="Drupal and WordPress Development Company, ICON Worldwide, development"/>
            <p>Software</p>
        </Link>
        <Link id="it-outsourcing" to="/services/nearshore-software-development">
            <img src={OutsourcingImg} alt="Drupal and WordPress Development Company, ICON Worldwide, nearshore" title="Drupal and WordPress Development Company, ICON Worldwide, nearshore"/>
            <p>Nearshore</p>
        </Link>
        <Link id="seo" to="/services/seo">
            <img src={SeoImg} alt="Drupal and WordPress Development Company, ICON Worldwide, seo" title="Drupal and WordPress Development Company, ICON Worldwide, seo"/>
            <p>SEO</p>
        </Link>
        <Link id="sem" to="/services/search-engine-marketing">
            <img src={SemImg} alt="Drupal and WordPress Development Company, ICON Worldwide, sem" title="Drupal and WordPress Development Company, ICON Worldwide, sem"/>
            <p>SEM</p>
        </Link>
    </div>